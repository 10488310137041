import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase';
class ImageCache {
  constructor() {
    this.cache = new Map();  // Cache les URLs des images
    this.initCache();
  }

  initCache() {
    // Récupère les données de cache depuis localStorage
    const savedCache = localStorage.getItem('imageCache');
    if (savedCache) {
      try {
        const parsedCache = JSON.parse(savedCache);
        if (parsedCache && typeof parsedCache === 'object') {
          Object.entries(parsedCache).forEach(([key, value]) => {
            this.cache.set(key, value);  // Stocke les URLs dans le cache
          });
        }
      } catch (error) {
        console.error('Erreur lors de la lecture du cache des images:', error);
        localStorage.removeItem('imageCache');
      }
    }
  }

  saveCache() {
    // Sauvegarde le cache dans localStorage
    const cacheObject = Object.fromEntries(this.cache);
    localStorage.setItem('imageCache', JSON.stringify(cacheObject));
  }

  async getImage(photoURL) {
    if (!photoURL) return null;

    // Si l'URL est déjà complète (non une référence Firebase), on renvoie directement
    if (photoURL.startsWith('http')) {
      return photoURL;
    }

    // Vérifier si l'image est déjà dans le cache
    if (this.cache.has(photoURL)) {
      //console.log('Image récupérée depuis le cache');
      return this.cache.get(photoURL);
    }

    try {
      // Si l'image n'est pas dans le cache, la télécharger depuis Firebase
      const imageRef = ref(storage, photoURL);
      const url = await getDownloadURL(imageRef);

      // Ajouter l'URL dans le cache
      this.cache.set(photoURL, url);
      this.saveCache();  // Sauvegarder dans localStorage

      //console.log('Image téléchargée et ajoutée au cache');
      return url;
    } catch (error) {
      console.error('Erreur lors du téléchargement de l\'image:', error);
      return null;
    }
  }

  clearCache() {
    this.cache.clear();  // Vider le cache
    localStorage.removeItem('imageCache');  // Supprimer du localStorage
  }
}

export const imageCache = new ImageCache();
