import { storage } from './firebase'; // Importez votre configuration Firebase
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { createUser } from './firebaseService'; // Assurez-vous d'importer la fonction de création d'utilisateur

// Fonction pour télécharger une image aléatoire depuis Picsum et l'uploader dans Firebase Storage
const uploadImageToFirebase = async (userId) => {
  const randomImageUrl = `https://picsum.photos/200/300?random=${Math.floor(Math.random() * 1000)}`; // Image aléatoire

  try {
    const response = await fetch(randomImageUrl); // Télécharger l'image
    const blob = await response.blob(); // Convertir l'image en Blob

    const imageRef = ref(storage, `profile_photos/${userId}.jpg`); // Référence Firebase pour l'image

    // Uploader l'image dans Firebase Storage
    await uploadBytes(imageRef, blob);
    //console.log(`Image pour l'utilisateur ${userId} téléchargée avec succès.`);

    // Obtenir l'URL de l'image téléchargée
    const downloadURL = await getDownloadURL(imageRef);
    return downloadURL;
  } catch (error) {
    console.error('Erreur lors de l\'upload de l\'image : ', error);
    return null;
  }
};

// Fonction pour générer des utilisateurs aléatoires avec des photos
export const generateRandomUsers = async () => {
  const fakeUsers = [];
  const firstNames = ['Alex', 'Marie', 'Pierre', 'Julie', 'Léa', 'Emma', 'Lucas', 'Chloé', 'Martin', 'Sophie', 'Louis', 'Clara', 'Hugo', 'Charlotte', 'Maxime', 'Sarah', 'Gabriel', 'Camille', 'Antoine', 'Alice', 'Benjamin', 'Paul', 'Lucie', 'Noé', 'Zoé', 'Arthur', 'Inès', 'Victor', 'Lise', 'Théo', 'Eva', 'Malo', 'Clément', 'Jade', 'Oscar', 'Maya', 'Raphaël', 'Lilou', 'Mathis', 'Louise', 'Léo', 'Mélanie', 'Julien', 'Amandine', 'Nathan', 'Véronique', 'Romain', 'Amélie', 'Mathilde', 'Enzo', 'Nina', 'Adam', 'Emma', 'Bastien', 'Audrey', 'Céline', 'Sacha', 'Pauline', 'Marius', 'Laurie', 'Lilian', 'Catherine', 'Victorine', 'Yann', 'Léna', 'Félix', 'Alice', 'Samuel', 'Camille', 'Charlotte', 'Gabin', 'Livia', 'Axel', 'Solène', 'Yasmine', 'Maëlle', 'Anaïs', 'Gustave', 'Emilie', 'Samuel', 'Béatrice', 'Chantal', 'Marion', 'Carla', 'Joana', 'Clémence', 'Alban', 'Arthur', 'Eva', 'Nicolas', 'Delphine', 'Jérôme', 'Philippe', 'Henri', 'Florence', 'Frédéric', 'Cécile', 'Sébastien', 'Sophie', 'François', 'Delphine', 'Amandine', 'Stéphane', 'Géraldine', 'Lucie', 'Sébastien', 'Luc', 'Élodie', 'Serge', 'Dominique', 'Laurent', 'Isabelle', 'Valérie', 'Hélène', 'Jacques', 'Georges', 'Nicole', 'Édith', 'Franck', 'Pierre-Alexandre', 'Sonia', 'Mélissa', 'Geneviève', 'Jean', 'Irène', 'Gilbert', 'Françoise', 'Maud'];
  const objectives = ['cerise', 'fraise', 'pastèque'];

  for (let i = 1; i <= 50; i++) {
    const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
    const randomObjective = objectives[Math.floor(Math.random() * objectives.length)];
    
    const userData = {
      pseudo: `${randomFirstName}${i}`,
      age: '35-45',
      createdAt: new Date().toISOString(),
      genre: i % 2 === 0 ? 'H' : 'F', // Alterner entre homme et femme
      notificationsEnabled: true,
      objectif: randomObjective,
      photoURL: null, // L'URL de l'image sera ajoutée après le téléchargement
    };

    // Télécharger et obtenir l'URL de l'image
    const imageUrl = await uploadImageToFirebase(i);
    if (imageUrl) {
      userData.photoURL = imageUrl; // Associer l'URL de l'image
    }

    fakeUsers.push(userData);
  }

  // Créer les utilisateurs dans Firestore
  for (const user of fakeUsers) {
    const success = await createUser(user); // Utiliser la fonction Firebase pour créer un utilisateur
    if (success) {
      //console.log(`Utilisateur ${user.pseudo} créé avec succès.`);
    } else {
      //console.log(`Échec de la création de l'utilisateur ${user.pseudo}.`);
    }
  }
};
