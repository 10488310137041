import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';

export async function createUser(userData) {
  const db = getFirestore();
  const userRef = doc(db, 'utilisateurs', userData.pseudo);
  
  try {
    await setDoc(userRef, {
      ...userData,
      last_connection: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error creating user:', error);
    return false;
  }
}

export async function updateUserConnection(userId) {
  const db = getFirestore();
  try {
    await setDoc(doc(db, 'utilisateurs', userId), {
      last_connection: serverTimestamp()
    }, { merge: true });
    return true;
  } catch (error) {
    console.error('Error updating user connection:', error);
    return false;
  }
}
