import React, { useState, useEffect } from 'react';
import { useChat } from '../contexts/ChatContext';
import { imageCache } from '../services/imageCache';
import './NavBar.css';

export default function NavBar() {
  const { currentStep, setCurrentStep, currentUser } = useChat();
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    const loadPhoto = async () => {
      if (currentUser?.photoURL) {
        try {
          const url = await imageCache.getImage(currentUser.photoURL);
          setPhotoURL(url);
        } catch (error) {
          console.error('Error loading profile photo:', error);
          setPhotoURL(null);
        }
      }
    };
    loadPhoto();
  }, [currentUser?.photoURL]);

  return (
    <nav className="navbar">
      <button 
        className={`nav-link ${currentStep === 'participants' ? 'active' : ''}`}
        onClick={() => setCurrentStep('participants')}
      >
        <i className="mdi mdi-account-multiple-outline"></i>
      </button>
      
      <button 
        className={`nav-link ${currentStep === 'conversations' ? 'active' : ''}`}
        onClick={() => setCurrentStep('conversations')}
      >
        <i className="mdi mdi-message-processing-outline"></i>
      </button>
      
      <button 
        className={`nav-link profile-icon ${currentStep === 'profile' ? 'active' : ''}`}
        onClick={() => setCurrentStep('profile')}
      >
        {photoURL ? (
          <img src={photoURL} alt="Profil" className="profile-photo" />
        ) : (
          <i className="mdi mdi-account-outline"></i>
        )}
      </button>
    </nav>
  );
}
