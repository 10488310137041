import { db } from './firebase';
import { collection, query, onSnapshot, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';
import { browserNotificationService } from './browserNotificationService';
import { fcmConfig } from '../firebase-config';
import firebaseApp from './firebase';

class NotificationService {
  constructor() {
    this.listeners = new Map();
    this.unsubscribe = null;
    this.messageUnsubscribes = new Set();
    this.initialized = false;
  }

  async initialize() {
    if (this.initialized) return;
    
    try {
      // Initialize browser notification service
      await browserNotificationService.initializeMessaging(firebaseApp);
      this.initialized = true;
      //console.log('Notification service initialized successfully');
    } catch (error) {
      console.error('Failed to initialize notification service:', error);
      this.initialized = false;
    }
  }

  async ensureInitialized() {
    if (!this.initialized) {
      await this.initialize();
    }
  }

  async sendFCMNotification(userNumero, notificationData) {
    try {
      await this.ensureInitialized();

      // Get Firebase token from backend
      const tokenResponse = await fetch('https://lajava.timetomeet.fr/php/get_firebase_token.php?api_token=c61066fac9c65bb78295c578c217ac52');
      const tokenData = await tokenResponse.json();

      if (!tokenResponse.ok || !tokenData.token) {
        console.error('Error getting Firebase token:', tokenData);
        return {
          success: false,
          error: 'Failed to get Firebase token',
          details: tokenData
        };
      }

      const firebaseToken = tokenData.token;

      // Check if the user has notifications enabled
      const userDoc = await getDoc(doc(db, 'utilisateurs', userNumero));
      const userData = userDoc.data();

      if (!userData?.fcmToken || !userData.notificationsEnabled) {
        return {
          success: false,
          error: 'User notifications not enabled',
          details: { userNumero, hasToken: !!userData?.fcmToken, enabled: !!userData?.notificationsEnabled }
        };
      }

      // Prepare FCM request
      const fcmUrl = `https://fcm.googleapis.com/v1/projects/${fcmConfig.projectId}/messages:send`;
      const fcmData = {
        message: {
          token: userData.fcmToken,
          notification: {
            title: notificationData.title,
            body: notificationData.body,
            image: notificationData.icon
          },
          data: notificationData.data
        }
      };

      // Send FCM notification
      const response = await fetch(fcmUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${firebaseToken}`
        },
        body: JSON.stringify(fcmData)
      });

      const result = await response.json();

      if (!response.ok) {
        console.error('FCM notification failed:', result);
        return {
          success: false,
          status: response.status,
          statusText: response.statusText,
          error: result.error || 'Unknown error',
          details: result
        };
      }

      //console.log('FCM notification sent successfully:', result);
      return {
        success: true,
        status: response.status,
        statusText: response.statusText,
        details: result
      };

    } catch (error) {
      console.error('Error sending FCM notification:', error);
      return {
        success: false,
        error: error.message || 'Unknown error occurred',
        stack: error.stack
      };
    }
  }

  async startListening(currentUserPseudo, onNewMessage) {
    await this.ensureInitialized();

    if (this.unsubscribe) {
      this.unsubscribe();
    }
    
    this.stopMessageListeners();

    const chatsRef = collection(db, 'chats');
    const q = query(chatsRef);
    
    this.unsubscribe = onSnapshot(q, async (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === 'modified') {
          const chatData = change.doc.data();
          const participants = chatData.participants || [];
          
          if (participants.includes(currentUserPseudo.toUpperCase())) {
            const messagesRef = collection(db, 'chats', change.doc.id, 'messages');
            const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));
            
            const unsubMessages = onSnapshot(messagesQuery, async (messagesSnapshot) => {
              const messages = messagesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
              }));
              
              if (messages.length > 0) {
                const lastMessage = messages[0];
                
                if (lastMessage.sender !== currentUserPseudo.toUpperCase()) {
                  try {
                    const usersRef = collection(db, 'utilisateurs');
                    const usersQuery = query(usersRef);
                    const usersSnapshot = await getDocs(usersQuery);
                    
                    let senderData = null;
                    usersSnapshot.forEach((doc) => {
                      const userData = doc.data();
                      if (userData.pseudo.toUpperCase() === lastMessage.sender) {
                        senderData = userData;
                      }
                    });

                    if (senderData) {
                      const notificationData = {
                        title: `Nouveau message de ${senderData.pseudo}`,
                        body: lastMessage.type === 'text' ? lastMessage.content : '📷 Photo',
                        icon: senderData.photoURL || '/resources/img/avatar-default.png',
                        data: {
                          senderPseudo: senderData.pseudo,
                          messageId: lastMessage.id,
                          chatId: change.doc.id
                        }
                      };

                      // Only show browser notification if we're not in the chat screen
                      const currentPath = window.location.pathname;
                      const inChatScreen = currentPath.includes('/');

                      // Always try to show browser notification if not in chat screen
                      if (!inChatScreen) {
                        try {
                          await browserNotificationService.showNotification(
                            notificationData.title,
                            {
                              body: notificationData.body,
                              icon: notificationData.icon,
                              data: notificationData.data,
                              onClick: () => {
                                window.focus();
                                onNewMessage(
                                  {
                                    ...lastMessage,
                                    photoURL: senderData.photoURL
                                  },
                                  senderData.pseudo
                                );
                              }
                            }
                          );
                        } catch (error) {
                          console.error('Error showing browser notification:', error);
                        }

                        // Only send FCM if not in chat screen
                        if (senderData.numero) {
                          const response = await this.sendFCMNotification(senderData.numero, notificationData);
                          if (!response.success) {
                            console.error('Error sending FCM notification:', response);
                          }
                        }
                      }
                    }
                  } catch (error) {
                    console.error('Error processing new message:', error);
                  }
                }
              }
            });
            
            this.messageUnsubscribes.add(unsubMessages);
          }
        }
      });
    });
  }

  stopMessageListeners() {
    // Unsubscribe from all message listeners
    for (const unsubscribe of this.messageUnsubscribes) {
      unsubscribe();
    }
    this.messageUnsubscribes.clear();
  }

  stopListening() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    this.stopMessageListeners();
  }

  async requestNotificationPermission() {
    return Notification.requestPermission().then(permission => {
      localStorage.setItem('notificationsEnabled', permission === 'granted');
      return permission;
    });
  }
}

export const notificationService = new NotificationService();