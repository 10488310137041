import React from 'react';
import './UserInfoModal.css';

const UserInfoModal = ({ isOpen, onClose, photoURL, username }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="user-info-modal-overlay">
      <div className="user-info-modal">
        <button className="user-info-modal-close" onClick={onClose}>X</button>
        <img src={photoURL} alt="Profile" className="user-info-modal-avatar" />
        <h2 className="user-info-modal-username">{username}</h2>
      </div>
    </div>
  );
};

export default UserInfoModal;
