import React, { useEffect, useState } from 'react';
import { useChat } from '../contexts/ChatContext';
import './MessageNotification.css';

const MessageNotification = ({ notification, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const { goToChat } = useChat();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 300); // Wait for animation to complete
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible || !notification || !notification.message || !notification.sender) {
    return null;
  }

  const handleClick = () => {
    goToChat(notification.sender);
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  return (
    <div 
      className={`message-notification ${isVisible ? 'show' : 'hide'}`}
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="notification-content">
        {notification.senderPhoto ? (
          <img 
            src={notification.senderPhoto}
            alt={`User ${notification.numero}`}
            className="notification-avatar"
            onError={(e) => {
              console.error('Error loading notification avatar');
              e.target.textContent = notification.emoji || '🍓';
              e.target.style.fontSize = '24px';
              e.target.style.display = 'flex';
              e.target.style.alignItems = 'center';
              e.target.style.justifyContent = 'center';
            }}
          />
        ) : (
          <div className="notification-avatar notification-emoji">
            {notification.emoji || '🍓'}
          </div>
        )}
        <div className="notification-text">
          <strong>{notification.numero}</strong>
          <p>{notification.message?.startsWith('https://') 
              ? 'Photo ou lien'
              : notification.message}</p>
        </div>
      </div>
      <button 
        className="notification-close" 
        onClick={(e) => {
          e.stopPropagation(); // Prevent triggering the parent's onClick
          setIsVisible(false);
          setTimeout(onClose, 300);
        }}
      >
        ×
      </button>
    </div>
  );
};

export default MessageNotification;
