import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { fcmConfig } from '../firebase-config';

class BrowserNotificationService {
  constructor() {
    this.messaging = null;
    this.hasPermission = false;
    this.onMessageCallback = null;
    this.isInitialized = false;
  }

  async initializeMessaging(app) {
    try {
      // Check if messaging is supported first
      const isMessagingSupported = await isSupported();
      if (!isMessagingSupported) {
        throw new Error('messaging/unsupported-browser');
      }

      this.messaging = getMessaging(app);
      this.isInitialized = true;
      return true;
    } catch (error) {
      console.error('Error initializing messaging:', error);
      this.isInitialized = false;
      throw error;
    }
  }

  getCurrentUserNumero() {
    try {
      const chatState = localStorage.getItem('chatState');
      if (chatState) {
        const parsed = JSON.parse(chatState);
        return parsed?.currentUser?.numero;
      }
    } catch (error) {
      console.error('Error getting user from localStorage:', error);
    }
    return null;
  }

  async initializeForUser(app, userNumero) {
    const storedNumero = this.getCurrentUserNumero();
    if (!userNumero && storedNumero) {
      userNumero = storedNumero;
    }
    
    if (!userNumero) {
      throw new Error('User numero is required');
    }

    try {
      // Initialize messaging first
      await this.initializeMessaging(app);

      if (!this.isInitialized) {
        throw new Error('messaging/not-initialized');
      }

      const userDoc = await getDoc(doc(db, 'utilisateurs', userNumero));
      const userData = userDoc.data();

      if (userData?.notificationsEnabled) {
        await this.requestPermission(userNumero);
      }

      onMessage(this.messaging, (payload) => {
        if (!payload.notification) return;

        this.showNotification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon,
          data: payload.data,
          onClick: () => {
            if (this.onMessageCallback && payload.data) {
              this.onMessageCallback(payload.data);
            }
          }
        });
      });

      const token = await this.getFcmToken(userNumero);
      return token;
    } catch (error) {
      console.error('Error initializing notifications:', error);
      if (error.message === 'messaging/not-initialized') {
        throw new Error('Le service de notifications n\'a pas pu être initialisé. Veuillez réessayer.');
      } else if (error.message === 'messaging/unsupported-browser') {
        throw new Error('Votre navigateur ne supporte pas les notifications push.');
      }
      throw error;
    }
  }

  setMessageCallback(callback) {
    this.onMessageCallback = callback;
  }

  async checkPermission() {
    if (!('Notification' in window)) {
      return false;
    }

    const permission = Notification.permission;
    this.hasPermission = permission === 'granted';
    return this.hasPermission;
  }

  async requestPermission(userNumero) {
    if (!('Notification' in window)) {
      return false;
    }

    try {
      const permission = await Notification.requestPermission();
      this.hasPermission = permission === 'granted';

      if (this.hasPermission) {
        await this.updateFcmToken(true, userNumero);
      }

      return this.hasPermission;
    } catch (error) {
      console.error('Error requesting permission:', error);
      return false;
    }
  }

  async getFcmToken(userNumero) {
    if (!this.messaging || !this.hasPermission) {
      return null;
    }

    try {
      const currentToken = await getToken(this.messaging, { 
        vapidKey: fcmConfig.vapidKey,
        serviceWorkerRegistration: await navigator.serviceWorker.getRegistration()
      });
      
      if (currentToken) {
        await this.updateFcmToken(true, userNumero);
      }
      return currentToken;
    } catch (error) {
      console.error('Error getting FCM token:', error);
      return null;
    }
  }

  async enableNotifications(userNumero) {
    const storedNumero = this.getCurrentUserNumero();
    if (!userNumero && storedNumero) {
      userNumero = storedNumero;
    }

    if (!userNumero) {
      return false;
    }

    try {
      if (!this.isInitialized) {
        throw new Error('messaging/not-initialized');
      }

      if (!this.hasPermission) {
        const granted = await this.requestPermission(userNumero);
        if (!granted) {
          throw new Error('messaging/permission-denied');
        }
      }

      const token = await this.getFcmToken(userNumero);
      if (!token) {
        throw new Error('messaging/token-error');
      }

      await this.updateFcmToken(true, userNumero);
      return true;
    } catch (error) {
      console.error('Error enabling notifications:', error);
      if (error.message === 'messaging/not-initialized') {
        throw new Error('Le service de notifications n\'a pas pu être initialisé. Veuillez réessayer.');
      } else if (error.message === 'messaging/permission-denied') {
        throw new Error('Les notifications ont été refusées. Veuillez les autoriser dans les paramètres de votre navigateur.');
      } else if (error.message === 'messaging/token-error') {
        throw new Error('Impossible d\'obtenir le jeton de notification. Veuillez réessayer.');
      }
      throw new Error('Une erreur est survenue lors de l\'activation des notifications. Veuillez réessayer.');
    }
  }

  async disableNotifications(userNumero) {
    const storedNumero = this.getCurrentUserNumero();
    if (!userNumero && storedNumero) {
      userNumero = storedNumero;
    }

    if (!userNumero) return;

    try {
      if (!this.isInitialized) {
        throw new Error('messaging/not-initialized');
      }
      await this.updateFcmToken(false, userNumero);
    } catch (error) {
      console.error('Error disabling notifications:', error);
      throw new Error('Une erreur est survenue lors de la désactivation des notifications. Veuillez réessayer.');
    }
  }

  async updateFcmToken(enabled, userNumero) {
    const storedNumero = this.getCurrentUserNumero();
    if (!userNumero && storedNumero) {
      userNumero = storedNumero;
    }

    if (!userNumero) {
      throw new Error('No user numero set');
    }

    try {
      let token = null;
      if (enabled) {
        if (!this.isInitialized) {
          throw new Error('messaging/not-initialized');
        }

        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
          throw new Error('messaging/no-sw-registration');
        }

        token = await getToken(this.messaging, { 
          vapidKey: fcmConfig.vapidKey,
          serviceWorkerRegistration: registration
        });

        if (!token) {
          throw new Error('messaging/token-error');
        }
      }

      const userRef = doc(db, 'utilisateurs', userNumero);
      await updateDoc(userRef, {
        fcmToken: token,
        fcmTokenUpdatedAt: new Date().toISOString(),
        notificationsEnabled: enabled
      });
    } catch (error) {
      console.error('Error updating notification status:', error);
      if (error.message === 'messaging/not-initialized') {
        throw new Error('Le service de notifications n\'a pas pu être initialisé. Veuillez réessayer.');
      } else if (error.message === 'messaging/no-sw-registration') {
        throw new Error('Le service worker n\'est pas enregistré. Veuillez rafraîchir la page.');
      } else if (error.message === 'messaging/token-error') {
        throw new Error('Impossible d\'obtenir le jeton de notification. Veuillez réessayer.');
      } else if (error.code === 'messaging/permission-blocked') {
        throw new Error('Les notifications sont bloquées. Veuillez les autoriser dans les paramètres de votre navigateur.');
      } else if (error.code === 'messaging/unsupported-browser') {
        throw new Error('Votre navigateur ne supporte pas les notifications push.');
      }
      throw new Error('Une erreur est survenue lors de la modification des notifications. Veuillez réessayer.');
    }
  }

  async showNotification(title, options = {}) {
    if (!this.hasPermission) return;

    try {
      const notification = new Notification(title, {
        ...options,
        icon: options.icon || '/resources/img/logo.png',
        badge: '/resources/img/logo.png',
        requireInteraction: true,
        renotify: true,
        tag: 'new-message'
        
      });

      notification.onclick = () => {
        window.focus();
        if (options.onClick) {
          options.onClick();
        }
      };

      try {
        const audio = new Audio('/resources/sound/notification.mp3');
        await audio.play();
      } catch (soundError) {
        // Ignore sound errors
      }
    } catch (error) {
      console.error('Error showing notification:', error);
      throw error;
    }
  }
}

export const browserNotificationService = new BrowserNotificationService();
