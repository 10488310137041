import React, { useState, useEffect } from 'react';
import './TVDisplayScreen.css';
import { db } from '../services/firebase';
import {
  collectionGroup,
  query,
  orderBy,
  onSnapshot,
  limit
} from 'firebase/firestore';

const TVDisplayScreen = () => {
  const [messages, setMessages] = useState([]);
  const [stats, setStats] = useState({
    totalMessages: 0
  });

  useEffect(() => {
    console.log('Setting up messages listener...');
    
    try {
      // Utiliser collectionGroup pour obtenir tous les messages de tous les chats
      const messagesQuery = query(
        collectionGroup(db, 'messages'),
        orderBy('timestamp', 'desc'),
        limit(50)
      );

      console.log('Query created, waiting for messages...');

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        try {
          console.log('Messages snapshot received:', snapshot.size, 'messages');
          
          // Gérer les changements en temps réel
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              const messageData = change.doc.data();
              console.log(`Message ${change.type}:`, {
                id: change.doc.id,
                content: messageData.content,
                type: messageData.type,
                timestamp: messageData.timestamp
              });
            }
          });

          // Mettre à jour tous les messages 
          const newMessages = snapshot.docs
            .map(doc => {
              const data = doc.data();
              console.log('Processing message:', {
                id: doc.id,
                content: data.content,
                type: data.type,
                timestamp: data.timestamp
              });
              return {
                id: doc.id,
                ...data
              };
            });

          console.log('Total messages processed:', newMessages.length);
          setMessages(newMessages);
          setStats(prevStats => ({
            ...prevStats,
            totalMessages: snapshot.size
          }));
        } catch (error) {
          console.error('Error processing messages:', error);
        }
      }, (error) => {
        console.error('Error in messages listener:', error);
      });

      return () => {
        console.log('Cleaning up messages listener...');
        unsubscribe();
      };
    } catch (error) {
      console.error('Error setting up messages listener:', error);
    }
  }, []);

  // Format timestamp
  const formatTime = (timestamp) => {
    if (!timestamp) return '';
    try {
      return new Date(timestamp.toDate()).toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (e) {
      console.error('Error formatting timestamp:', e);
      return '';
    }
  };

  return (
    <div className="tv-display">
      <div className="header">
        <h1>Live Chat</h1>
        <div className="stats">
          <div className="total-messages">
            Total Messages: {stats.totalMessages}
          </div>
        </div>
      </div>

      <div className="content">
        <div className="messages-section">

          <div className="messages-container">
            {messages.length > 0 ? (
              messages.map((message) => (
                <div key={message.id} className="message-card">
                  <div className="message-header">
                    <span className="message-user">{message.sender}</span>
                    <span className="message-time">{formatTime(message.timestamp)}</span>
                  </div>
                  <div className="message-content">
                    {message.type === 'image' ? (
                      <img 
                        src={message.content} 
                        alt="Message" 
                        className="message-image"
                        onError={(e) => {
                          console.error('Error loading image:', message.content);
                          e.target.src = '/resources/img/image-error.png';
                        }}
                      />
                    ) : (
                      message.content
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-messages">Pas de messages pour le moment...</div>
            )}
          </div>
        </div>
      </div>
      <div className="live-indicator">Live</div>
    </div>
  );
};

export default TVDisplayScreen;
