import React, { useEffect } from 'react'; // Correctement importé React et useEffect
import { ChatProvider } from './contexts/ChatContext';
import { useChat } from './contexts/ChatContext';
import HomeScreen from './screens/HomeScreen';
import CreateAccountScreen from './screens/CreateAccountScreen';
import ParticipantsScreen from './screens/ParticipantsScreen';
import ConversationsScreen from './screens/ConversationsScreen';
import ProfileScreen from './screens/ProfileScreen';
import ChatScreen from './screens/ChatScreen';
import CleanupScreen from './screens/CleanupScreen';
import TVDisplayScreen from './screens/TVDisplayScreen';
import StatisticsScreen from './screens/StatisticsScreen';
import NavBar from './components/NavBar';
import MessageNotification from './components/MessageNotification';
import UserNotification from './components/UserNotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './services/firebase'; // Initialise Firebase
import FirebaseNotificationTest from './screens/notifTest';
import InstallPrompt from './components/InstallPrompt';
import { generateRandomUsers } from './services/userService'; // Import de la fonction de génération d'utilisateurs

function AppContent() {
  const { currentStep, notifications, removeNotification } = useChat();
  const path = window.location.pathname;

  useEffect(() => {
    // Vérifiez si la route est '/generateRandomUsers' et effectuez la génération des utilisateurs uniquement dans ce cas
    if (path === '/generateRandomUsers') {
      const generateUsers = async () => {
        try {
          await generateRandomUsers(); // Appeler la fonction de génération d'utilisateurs
          alert('Les utilisateurs ont été générés avec succès !');
          window.location.href = '/'; // Rediriger vers la page d'accueil après la génération
        } catch (error) {
          console.error("Erreur lors de la génération des utilisateurs :", error);
          alert('Erreur lors de la génération des utilisateurs.');
        }
      };

      generateUsers();
    }
  }, [path]); // Le tableau de dépendances garantit que l'effet s'exécute uniquement lorsque `path` change

  // Determine which screen to show based on path
  if (path === '/statistics') {
    return <StatisticsScreen />;
  }

  if (path === '/tv-display') {
    return <TVDisplayScreen />;
  }

  if (path === '/cleanup') {
    return <CleanupScreen />;
  }

  // Déterminer le contenu principal
  let mainContent;
  const showNavBar = ['participants', 'conversations', 'profile', 'chat'].includes(currentStep);

  switch (currentStep) {
    case 'home':
      mainContent = <HomeScreen />;
      break;
    case 'civilite':
      mainContent = <CreateAccountScreen />;
      break;
    case 'participants':
      mainContent = <ParticipantsScreen />;
      break;
    case 'conversations':
      mainContent = <ConversationsScreen />;
      break;
    case 'profile':
      mainContent = <ProfileScreen />;
      break;
    case 'chat':
      mainContent = <ChatScreen />;
      break;
    case 'notifTest':
      mainContent = <FirebaseNotificationTest />;
      break;
    default:
      mainContent = <HomeScreen />;
  }

  return (
    <div className="app-wrapper">
    
      <div className="app-container">
        <InstallPrompt />  {/* Modale toutes les 5-10 min */}
        {mainContent}
        {showNavBar && <NavBar />}
        <div className="notifications-container">
          {notifications.map((notification) => 
            notification.type === 'message' ? (
              <MessageNotification
                key={notification.id}
                notification={notification}
                onClose={() => removeNotification(notification.id)}
              />
            ) : (
              <UserNotification
                key={notification.id}
                user={notification.user}
                onClose={() => removeNotification(notification.id)}
              />
            )
          )}
        </div>
      </div>
      <div className="heart">💖</div>
      <div className="heart">💘</div>
      <div className="heart">💕</div>
      <div className="heart">💞</div>

      
    </div>
  );
}

function App() {
  return (
    <ChatProvider>
      <ToastContainer />
      <AppContent />
    </ChatProvider>
  );
}

export default App;
