import React, { useState, useEffect } from 'react';
import './InstallPrompt.css';

const InstallPrompt = ({ forceShow }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallModal, setShowInstallModal] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const checkPWA = () => {
      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
        setIsPWA(true);
      }
    };

    const hasClosedPrompt = localStorage.getItem('hasClosedIOSPrompt');
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOSDevice = /iphone|ipad|ipod/.test(userAgent) && /safari/.test(userAgent) && !/chrome/.test(userAgent);
    setIsIOS(isIOSDevice && !hasClosedPrompt);

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      
      // Show install modal if forceShow is true
      if (forceShow) {
        setShowInstallModal(true);
      }
    };

    checkPWA();
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [forceShow]);

  const closeIOSPrompt = () => {
    localStorage.setItem('hasClosedIOSPrompt', 'true');
    setIsIOS(false);
  };

  const installApp = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      setDeferredPrompt(null);
      setShowInstallModal(false);
    }
  };

  if (isPWA || (!showInstallModal && !isIOS)) {
    return null;
  }

  // On ne montre la modale que pour iOS
  return (
    <div className="install-modal" onClick={(e) => {
      if (e.target.className === 'install-modal') {
        closeIOSPrompt();
      }
    }}>
      <div className="install-modal-content">
        {isIOS && (
          <>
            <h2>Activez les notifications sur votre iPhone ! 🔔</h2>
            <p>Pour recevoir des notifications importantes et ne rien manquer, ajoutez l'application à votre écran d'accueil.</p>
            <p><strong>👉 Voici comment faire :</strong></p>
            <ol>
              <li>Ouvrez le menu de partage en cliquant sur l'icône en bas de l'écran   <img src="/resources/img/partage.gif" alt="Appuyer sur Partager" className="install-gif" width={25}/></li>
              <li>Sélectionnez l'option "Ajouter à l'écran d'accueil".              <img src="/resources/img/add_home_ios.gif" alt="Ajouter à l'écran d'accueil" className="install-gif" />
              </li>
              <li>Confirmez en cliquant sur Ajouter.</li>
            </ol>
            <p>Une fois l'application ajoutée, vous pourrez recevoir nos notifications directement sur votre téléphone !</p>
           
            <button onClick={closeIOSPrompt}>OK</button>
          </>
        )}
      </div>
    </div>
  );
};

export default InstallPrompt;
