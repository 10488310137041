import React, { useState, useRef } from 'react';
import { useChat } from '../contexts/ChatContext';
import firebaseApp, { db, storage } from '../services/firebase';

import { doc, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { browserNotificationService } from '../services/browserNotificationService';
import { readAndCompressImage } from 'browser-image-resizer';
import { toast } from 'react-toastify';
import './CreateAccountScreen.css';

const STEPS = {
  GENRE: 0,
  PSEUDO: 1,
  AGE: 2,
  PHOTO: 3,
  CONFIRMPHOTO: 4,
  OBJECTIF: 5,
  RECAP: 6,
  NOTIFICATIONS: 7
};



const AGE_RANGES = [
  { label: 'De 18 à 25 ans', value: '18-25' },
  { label: 'De 25 à 35 ans', value: '25-35' },
  { label: 'De 35 à 45 ans', value: '35-45' },
  { label: 'De 45 à 55 ans', value: '45-55' },
  { label: '+ de 55 ans', value: '55+' }
];

const OBJECTIFS = [
  { 
    id: 'cerise',
    title: 'Trouver sa moitiée',
    icon: '/resources/img/icons/cerise_photo.png',
    color: '#FF4458'
  },
  {
    id: 'fraise',
    title: 'Une soirée aux plaisirs sucrés',
    icon: '/resources/img/icons/fraise_photo.png',
    color: '#FF6B81'
  },
  {
    id: 'pasteque',
    title: 'Des calins sans pépins',
    icon: '/resources/img/icons/pasteque_photo.png',
    color: '#FF8E9E'
  },
  {
    id: 'raisin',
    title: 'Un verre sans se prendre la grappe',
    icon: '/resources/img/icons/raisin_photo.png',
    color: '#FFA1B0'
  }
];

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

const isPWA = () => {
  return window.matchMedia('(display-mode: standalone)').matches || 
         window.navigator.standalone || 
         document.referrer.includes('android-app://');
};

export default function CreateAccountScreen() {
  const { setCurrentStep, currentUser, setCurrentUser } = useChat();
  const [currentStepIndex, setCurrentStepIndex] = useState(STEPS.GENRE);
  const [formData, setFormData] = useState({
    numero: currentUser.numero,
    genre: '',
    pseudo: '',
    age: '',
    photo: null,
    photoURL: '',
    objectif: '',
    notificationsEnabled: false
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(null);
  const [unresizedImage, setUnresizedImage] = useState(null);
  const photoInputRef = useRef(null);

  const handleRetakePhoto = () => {
    setUnresizedImage(null);
    setCurrentStepIndex(STEPS.PHOTO);
  };

  const handleGenreSelect = (genre) => {
    setFormData(prev => ({ ...prev, genre }));
    nextStep();
  };

  const handlePseudoSubmit = (e) => {
    e.preventDefault();
    if (formData.pseudo.length < 2 || formData.pseudo.length > 20) {
      setError('Le pseudo doit contenir entre 2 et 20 caractères');
      return;
    }
    setError('');
    nextStep();
  };

  const handleAgeSelect = (age) => {
    setFormData(prev => ({ ...prev, age }));
    nextStep();
  };

  const handlePhotoSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setError('La photo ne doit pas dépasser 5MB');
      return;
    }

    try {
      setFormData(prev => ({ 
        ...prev, 
        photo: file,
        photoURL: URL.createObjectURL(file)
      }));
      nextStep();
    } catch (error) {
      console.error('Error with photo:', error);
      setError('Erreur lors du chargement de la photo');
    }
  };

  const uploadPhoto = async (file, numero) => {
    // Utiliser .jpg comme extension par défaut pour les Blobs compressés
    const fileName = `${numero}_${Date.now()}.jpg`;
    const storageRef = ref(storage, `profile_photos/${fileName}`);
    
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');

    try {
      // Upload de la photo
      let photoURL = '';
      if (formData.photo) {
        photoURL = await uploadPhoto(formData.photo, formData.numero);
      }

      // Création du document utilisateur
      const userData = {
        numero: formData.numero,
        pseudo: formData.pseudo.toUpperCase(),
        genre: formData.genre,
        age: formData.age,
        objectif: formData.objectif,
        photoURL: photoURL,
        notificationsEnabled: formData.notificationsEnabled,
        createdAt: new Date().toISOString(),
        lastActive: new Date().toISOString()
      };

      // Sauvegarde dans Firestore
      await setDoc(doc(db, 'utilisateurs', formData.numero), userData);

      // Mise à jour du contexte
      setCurrentUser(userData);

      // Passer à l'étape des notifications
      setCurrentStepIndex(STEPS.NOTIFICATIONS);
      setLoading(false);
    } catch (error) {
      console.error('Error creating account:', error);
      setError('Erreur lors de la création du compte. Veuillez réessayer.');
      setLoading(false);
    }
  };

  const handleObjectifSelect = (objectif) => {
    setFormData(prev => ({ ...prev, objectif }));
    nextStep();
  };

  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

const showSuccessMessage = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
};


const showErrorMessage = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

  const skipNotifications = () => {
    setFormData(prev => ({ ...prev, notificationsEnabled: false }));
    localStorage.setItem('chatState', JSON.stringify({
      currentUser: { ...currentUser, notificationsEnabled: false },
      currentStep: 'participants'
    }));
    setCurrentStep('participants');
  };

  const nextStep = () => {
    const nextStepIndex = currentStepIndex + 1;
    
    // Skip notifications step if on iOS but not in PWA
    if (nextStepIndex === STEPS.NOTIFICATIONS && isIOS() && !isPWA()) {
      setFormData(prev => ({ ...prev, notificationsEnabled: false }));
      setCurrentStepIndex(STEPS.RECAP);
    } else {
      setCurrentStepIndex(nextStepIndex);
    }
  };

  const prevStep = () => {
    setCurrentStepIndex(prev => prev - 1);
  };

  const renderStep = () => {
    switch (currentStepIndex) {
      case STEPS.GENRE:
        return (
          <div className="step-container genre-step">
            <h2>Tu es ... ?</h2>
            <div className="genre-buttons">
              <button 
                className={`genre-button ${formData.genre === 'H' ? 'selected' : ''}`}
                onClick={() => handleGenreSelect('H')}
              >
                <i className="fas fa-mars"></i>
                <span>Homme</span>
              </button>
              <button 
                className={`genre-button ${formData.genre === 'F' ? 'selected' : ''}`}
                onClick={() => handleGenreSelect('F')}
              >
                <i className="fas fa-venus"></i>
                <span>Femme</span>
              </button>
            </div>
          </div>
        );

      case STEPS.PSEUDO:
        return (
          <div className="step-container pseudo-step">
            <h2>Quel sera ton pseudo ce soir ... </h2>
            <button className="back-button" onClick={prevStep}><i className="fas fa-chevron-left"></i></button>
            <form onSubmit={handlePseudoSubmit}>
              <input
                type="text"
                value={formData.pseudo}
                onChange={e => setFormData(prev => ({ ...prev, pseudo: e.target.value }))}
                placeholder="Ton pseudo"
                maxLength={20}
                className="input-field"
              />
              <button type="submit" className="next-button">Suivant</button>
            </form>
            {error && <div className="error-message">{error}</div>}
          </div>
        );

      case STEPS.AGE:
        return (
          <div className="step-container age-step">
            <h2>Quel âge as-tu ?</h2>
            <button className="back-button" onClick={prevStep}><i className="fas fa-chevron-left"></i></button>
            <div className="age-options">
              {AGE_RANGES.map(range => (
                <button
                  key={range.value}
                  className={`age-button ${formData.age === range.value ? 'selected' : ''}`}
                  onClick={() => handleAgeSelect(range.value)}
                >
                  {range.label}
                </button>
              ))}
            </div>
          </div>
        );

      case STEPS.PHOTO:
        return (
          <div className="step-container photo-step">
            <h2>Ajouter votre photo de profil</h2>
            <button className="back-button" onClick={prevStep}><i className="fas fa-chevron-left"></i></button>
            <div className="photo-upload-container">
              {unresizedImage ? (
                <div className="preview-container">
                  <img
                    src={URL.createObjectURL(unresizedImage)}
                    alt="Preview"
                    className="photo-preview"
                  />
                  <button className="change-photo-button" onClick={() => photoInputRef.current.click()}>
                    Changer
                  </button>
                </div>
              ) : (
                <div className="upload-placeholder" onClick={() => photoInputRef.current.click()}>
                  <i className="fas fa-camera"></i>
                  <span>Cliquer pour ajouter</span>
                </div>
              )}
              <input
                ref={photoInputRef}
                id="photo-input"
                type="file"
                accept="image/*"
                onChange={async (e) => {
                  if (e.target.files && e.target.files[0]) {
                    const config = { maxWidth: 1024, maxHeight: 1024, quality: 0.7 };
                    const image = await readAndCompressImage(e.target.files[0], config);
                    setUnresizedImage(image);
                    setFormData(prev => ({
                      ...prev,
                      photo: image,
                      photoURL: URL.createObjectURL(image)
                    }));
                    nextStep();
                  }
                }}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        );

      case STEPS.CONFIRMPHOTO:
        return (
          <div className="step-container confirm-photo-step">
            <h2>Confirmer votre photo ?</h2>
            <button className="back-button" onClick={prevStep}><i className="fas fa-chevron-left"></i></button>
            <div className="centered-image-container">
              <img
                src={unresizedImage ? URL.createObjectURL(unresizedImage) : null}
                alt="Preview"
                style={{ maxWidth: '200px', maxHeight: '200px' }}
              />
            </div>
            <button className="next-button" onClick={() => prevStep()}>Retake Photo</button>
            <button 
              className="next-button" 
              onClick={() => {
                if (unresizedImage) {
                  setFormData(prev => ({
                    ...prev,
                    photo: unresizedImage,
                    photoURL: URL.createObjectURL(unresizedImage)
                  }));
                  nextStep();
                }
              }}
            >
              Confirm Photo
            </button>
          </div>
        );

      case STEPS.OBJECTIF:
        return (
          <div className="step-container objectif-step">
            <h2>Tu es là ce soir pour ... </h2>
            <button className="back-button" onClick={prevStep}><i className="fas fa-chevron-left"></i></button>
            <div className="objectifs-grid">
              {OBJECTIFS.map(obj => (
                <button
                  key={obj.id}
                  className={`objectif-card ${formData.objectif === obj.id ? 'selected' : ''}`}
                  onClick={() => handleObjectifSelect(obj.id)}
                  style={{ '--card-color': obj.color }}
                >
                  <img src={obj.icon} alt={obj.title} className="objectif-icon" />
                  <span className="objectif-title">{obj.title}</span>
                </button>
              ))}
            </div>
          </div>
        );

      case STEPS.RECAP:
        return (
          <div className="step-container recap-step">
            <h2>C'est l'heure des plaisirs éphémères !</h2>
            <button className="back-button" onClick={prevStep}><i className="fas fa-chevron-left"></i></button>
            <div className="recap-content">
              <div className="recap-photo">
                <img src={formData.photoURL} alt="Profile" />
              </div>
              <div className="recap-info">
                <p><strong>Pseudo:</strong> {formData.pseudo}</p>
                <p><strong>Genre:</strong> {formData.genre === 'H' ? 'Homme' : 'Femme'}</p>
                <p><strong>Âge:</strong> {AGE_RANGES.find(r => r.value === formData.age)?.label}</p>
                <p><strong>Objectif:</strong> {OBJECTIFS.find(o => o.id === formData.objectif)?.title}</p>
              </div>
              <div className="ephemeral-message">
                <div className="ephemeral-icon">
                  <i className="fas fa-hourglass-half"></i>
                </div>
                <p>
                  Carpe Diem ! Profitez de l'instant présent car demain est un autre jour. 
                  Vos conversations disparaîtront dans 24h, laissant place à de nouvelles rencontres excitantes.
                </p>
                <p className="ephemeral-highlight">
                  La vie est courte, les plaisirs sont éphémères... Savourez chaque instant !
                </p>
              </div>
              <button 
                className="submit-button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Création...' : 'Commencer l\'aventure !'}
              </button>
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
        );

        case STEPS.NOTIFICATIONS:
          return (
            <div className="step-container notifications-step">
              <h2>Activer les notifications</h2>
              <button className="back-button" onClick={prevStep}><i className="fas fa-chevron-left"></i></button>
              <div className="notifications-content">
                <div className="notification-icon">
                  <i className="fas fa-bell"></i>
                </div>
                <p className="notification-description">
                  Ne manquez aucun message ! Activez les notifications pour être informé des nouveaux messages même lorsque l'application n'est pas ouverte.
                </p>
        
                {notificationPermission === true ? (
                  <div className="notification-success">
                    <p><i className="fas fa-check-circle"></i> Les notifications sont activées !</p>
                    <button 
                      className="continue-button"
                      onClick={() => {
                        localStorage.setItem('chatState', JSON.stringify({
                          currentUser: { ...currentUser, notificationsEnabled: true },
                          currentStep: 'participants'
                        }));
                        setCurrentStep('participants');
                      }}
                    >
                      Continuer
                    </button>
                  </div>
                ) : notificationPermission === false ? (
                  <div className="notification-warning">
                    <p>Les notifications sont bloquées par votre navigateur.</p>
                    <button onClick={skipNotifications}>
                      Continuer sans notifications
                    </button>
                  </div>
                ) : (
                  <div className="notification-buttons">
                    <button 
                      onClick={async () => {
                        try {
                          setLoading(true);
        
                          if (!formData.numero) {
                            setError("Utilisateur non connecté");
                            setLoading(false);
                            return;
                          }
        
                          // Vérification préalable du statut des notifications
                          await browserNotificationService.initializeForUser(firebaseApp, formData.numero);
                          const permission = await browserNotificationService.checkPermission();
                          setNotificationPermission(permission);
        
                          if (permission) {
                            // Vérifie en base si les notifications sont déjà activées
                            const userDoc = await getDoc(doc(db, 'utilisateurs', formData.numero));
                            const userData = userDoc.data();
                            setNotificationsEnabled(userData?.notificationsEnabled || false);
        
                            if (!userData?.notificationsEnabled) {
                              // Demande la permission si elle n'a pas été accordée avant
                              const userPermission = await Notification.requestPermission();
                              if (userPermission === 'granted') {
                                const success = await browserNotificationService.enableNotifications(formData.numero);
                                if (success) {
                                  setNotificationPermission(true);
                                  setFormData(prev => ({ ...prev, notificationsEnabled: true }));
                                  showSuccessMessage('Notifications activées avec succès');
                                } else {
                                  setError('Erreur lors de l\'activation des notifications');
                                }
                              } else {
                                setNotificationPermission(false);
                                setError('Les notifications ont été refusées');
                              }
                            }
                          } else {
                            setNotificationPermission(false);
                            setError('Les notifications sont bloquées par votre navigateur.');
                          }
                        } catch (error) {
                          console.error('Error details:', error);
                          setError('Erreur lors de l\'activation des notifications: ' + error.message);
                        } finally {
                          setLoading(false);
                        }
                      }}
                      className="request-permission-button"
                    >
                      Autoriser les notifications
                    </button>
                    <button className="skip-button" onClick={skipNotifications}>
                      Plus tard
                    </button>
                  </div>
                )}
        
                {error && <div className="error-message">{error}</div>}
              </div>
            </div>
          );
        

      default:
        return null;
    }
  };

  return (
    <div className="create-account-screen">
      <div 
        className="progress-bar"
        style={{ 
          '--progress-percentage': currentStepIndex / (Object.keys(STEPS).length - 1)
        }}
      >
        {Object.values(STEPS).map(step => (
          <div 
            key={step}
            className={`progress-dot ${currentStepIndex >= step ? 'active' : ''}`}
          />
        ))}
      </div>
      {renderStep()}
    </div>
  );
}
