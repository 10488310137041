import React, { useEffect, useState } from 'react';
import { db } from '../services/firebase';
import { collection, query, onSnapshot, orderBy, limit } from 'firebase/firestore';
import './StatisticsScreen.css';

const StatisticsScreen = () => {
  const [topPhotosUser, setTopPhotosUser] = useState(null);
  const [topActiveConversationsUser, setTopActiveConversationsUser] = useState(null);
  const [topMessagesUser, setTopMessagesUser] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log('Mise à jour des statistiques...');

      // Écouteur pour l'utilisateur avec le plus de photos
      const photosQuery = query(collection(db, 'utilisateurs'), orderBy('photosCount', 'desc'), limit(1));
      const photosUnsubscribe = onSnapshot(photosQuery, (snapshot) => {
        if (!snapshot.empty) {
          setTopPhotosUser(snapshot.docs[0].data());
        }
      });

      // Écouteur pour l'utilisateur avec le plus de conversations actives
      const conversationsQuery = query(collection(db, 'utilisateurs'), orderBy('activeConversations', 'desc'), limit(1));
      const conversationsUnsubscribe = onSnapshot(conversationsQuery, (snapshot) => {
        if (!snapshot.empty) {
          setTopActiveConversationsUser(snapshot.docs[0].data());
        }
      });

      // Écouteur pour l'utilisateur avec le plus de messages
      const messagesQuery = query(collection(db, 'utilisateurs'), orderBy('messagesCount', 'desc'), limit(1));
      const messagesUnsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        if (!snapshot.empty) {
          setTopMessagesUser(snapshot.docs[0].data());
        }
      });

      return () => {
        photosUnsubscribe();
        conversationsUnsubscribe();
        messagesUnsubscribe();
      };
    }, 5000); // Mise à jour toutes les 5 secondes

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="statistics-screen">
      <h1>Statistiques en temps réel</h1>
      
      <div className="statistics-container">
        <div className="statistic-card">
          <h2>Top Photographe</h2>
          {topPhotosUser ? (
            <p>{topPhotosUser.username} - {topPhotosUser.photosCount} photos</p>
          ) : (
            <p>Chargement...</p>
          )}
        </div>

        <div className="statistic-card">
          <h2>Top Conversations Actives</h2>
          {topActiveConversationsUser ? (
            <p>{topActiveConversationsUser.username} - {topActiveConversationsUser.activeConversations} conversations</p>
          ) : (
            <p>Chargement...</p>
          )}
        </div>

        <div className="statistic-card">
          <h2>Top Messages</h2>
          {topMessagesUser ? (
            <p>{topMessagesUser.username} - {topMessagesUser.messagesCount} messages</p>
          ) : (
            <p>Chargement...</p>
          )}
        </div>
      </div>
      v1
    </div>
  );
};

export default StatisticsScreen;