import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useChat } from '../contexts/ChatContext';
import { imageCache } from '../services/imageCache';
import { collection, onSnapshot, query, getDocs, orderBy, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import './ParticipantsScreen.css';
import UserAvatar from '../components/UserAvatar';
import { browserNotificationService } from '../services/browserNotificationService';
import firebaseApp from '../services/firebase';
import { toast } from 'react-toastify';

const OBJECTIFS = [
  { 
    id: 'cerise',
    title: 'Trouver sa moitiée',
    icon: '/resources/img/icons/cerise_photo.png',
    color: '#FF4458'
  },
  {
    id: 'fraise',
    title: 'Une soirée aux plaisirs sucrés',
    icon: '/resources/img/icons/fraise_photo.png',
    color: '#FF6B81'
  },
  {
    id: 'pasteque',
    title: 'Des calins sans pépins',
    icon: '/resources/img/icons/pasteque_photo.png',
    color: '#FF8E9E'
  },
  {
    id: 'raisin',
    title: 'Un verre sans se prendre la grappe',
    icon: '/resources/img/icons/raisin_photo.png',
    color: '#FFA1B0'
  }
];

const getObjectifEmoji = (objectif) => {
  const objectifId = typeof objectif === 'string' ? objectif : objectif?.id;
  switch (objectifId?.toLowerCase()) {
    case 'fraise': return '🍓';
    case 'raisin': return '🍇';
    case 'pasteque':
    case 'pastèque': return '🍉';
    case 'cerise': return '🍒';
    default: return '🍒';
  }
};

const getObjectifTitle = (objectif) => {
  const found = OBJECTIFS.find(obj => obj.id === objectif?.id?.toLowerCase());
  return found ? found.title : '';
};

const getAgeRange = (age) => {
  switch (age) {
    case '18-25': return '18-25 ans';
    case '25-35': return '25-35 ans';
    case '35-45': return '35-45 ans';
    case '45-55': return '45-55 ans';
    case '55+': return '55+ ans';
    default: return '';
  }
};

const ParticipantCard = React.memo(({ participant, photo, onClick, index, hasUnreadMessages }) => {
  console.log('Rendering ParticipantCard:', participant.numero);
  const genderClass = participant?.genre === 'H' ? 'male' : participant?.genre === 'F' ? 'female' : '';
  const randomDelay = -1 * (Math.random() * 2); // Random delay between 0 and 2 seconds
  
  return (
    <div 
      className={`participant-card ${genderClass}`}
      onClick={onClick}
      style={{
        animationDelay: `${randomDelay}s`
      }}
    >
      <div className="participant-avatar-container">
        <img
          src={photo || participant.photoURL || '/resources/img/avatar-default.png'}
          alt={participant.pseudo}
          className={`participant-avatar ${genderClass}`}
          onError={(e) => {
            e.target.src = '/resources/img/avatar-default.png';
          }}
        />
        {hasUnreadMessages && <div className="notification-dot"></div>}
        <div className="bottom-indicators">
          {participant.numero && (
            <div className="participant-number">
              {participant.numero}
            </div>
          )}
          <div className="location-indicator">
            <span className="objectif-emoji">{getObjectifEmoji(participant.objectif)}</span>
          </div>
        </div>
      </div>
      <div className="participant-name">{participant.pseudo}</div>
    </div>
  );
});

const ParticipantPopup = ({ participant, onClose, handleChat }) => {
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    const loadPhoto = async () => {
      if (participant.photo) {
        try {
          const url = await imageCache.getImage(participant.photo);
          setPhotoURL(url);
        } catch (error) {
          console.error('Error loading photo:', error);
          setPhotoURL(participant.photoURL || null);
        }
      } else if (participant.photoURL) {
        setPhotoURL(participant.photoURL);
      }
    };
    loadPhoto();
  }, [participant.photo, participant.photoURL]);

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        {photoURL && <img src={photoURL} alt="" className="popup-background-photo" />}
        <div className="popup-gradient-overlay"></div>
        <div className="popup-header">
          <h2>
          <span className="objectif-emoji">{getObjectifEmoji(participant.objectif)}</span>
            <span className="participant-number">{participant.numero}</span>
            <span className="objectif-emoji">{getObjectifEmoji(participant.objectif)}</span>
          </h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="popup-body">
          <div className="participant-photo">
            {photoURL ? (
              <img src={photoURL} alt={participant.pseudo} />
            ) : (
              <div className="photo-placeholder">
                <span>{participant.pseudo[0].toUpperCase()}</span>
              </div>
            )}
          </div>
          <div className="participant-details">
            <div className="detail-item">
              <span className="label">Numéro</span>
              <span className="value numero">#{participant.numero}</span>
            </div>
            <div className="detail-item">
              <span className="label">Objectif</span>
              <span className="value">
                <span className="objectif-emoji">{getObjectifEmoji(participant.objectif)}</span>
                {OBJECTIFS.find(obj => obj.id === participant.objectif)?.title}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Âge</span>
              <span className="value">{participant.age} ans</span>
            </div>
            <div className="detail-item">
              <span className="label">Genre</span>
              <span className="value">{participant.genre === 'H' ? 'Homme' : 'Femme'}</span>
            </div>
          </div>
        </div>
        
        <div className="popup-footer">
          <button 
            className={`chat-button ${participant.genre === 'H' ? 'male' : ''}`}
            onClick={() => handleChat(participant)}
          >
            Discuter
          </button>
        </div>
      </div>
    </div>
  );
};

const ParticipantsScreen = () => {
  const { currentUser, setCurrentStep, setSelectedClient, clients, startUsersListener } = useChat();
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [participantPhotos, setParticipantPhotos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [selectedGenders, setSelectedGenders] = useState(new Set()); 
  const [selectedObjectives, setSelectedObjectives] = useState(new Set()); 
  const [selectedAgeRanges, setSelectedAgeRanges] = useState(new Set()); 
  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false);

  useEffect(() => {
    const checkAndRequestNotifications = async () => {
      if (!currentUser?.numero) return;

      try {
        // Vérifier si les notifications sont déjà activées
        const userDoc = await getDoc(doc(db, 'utilisateurs', currentUser.numero));
        const userData = userDoc.data();
        
        if (!userData?.notificationsEnabled) {
          // Demander la permission
          const permission = await Notification.requestPermission();
          
          if (permission === 'granted') {
            // Initialiser Firebase Messaging
            await browserNotificationService.initializeForUser(firebaseApp, currentUser.numero);
            await browserNotificationService.enableNotifications(currentUser.numero);
            toast.success('Notifications activées avec succès');
          }
        }
      } catch (error) {
        console.error('Error checking notifications:', error);
      }
    };

    checkAndRequestNotifications();
  }, [currentUser?.numero]);

  const loadParticipantPhotos = useCallback(async () => {
    const photos = { ...participantPhotos };
    const newParticipants = clients.filter(
      p => !photos[p.numero] && p.photoURL && p.numero !== currentUser?.numero
    );

    if (newParticipants.length === 0) {
      setIsLoading(false);
      return;
    }

    try {
      await Promise.all(
        newParticipants.map(async (participant) => {
          if (!participant.photoURL) return;
          
          try {
            const cachedURL = await imageCache.getImage(participant.photoURL);
            if (cachedURL) {
              photos[participant.numero] = cachedURL;
            } else {
              photos[participant.numero] = participant.photoURL;
              await imageCache.addImage(participant.photoURL);
            }
          } catch (error) {
            console.error('Error loading photo for:', participant.numero, error);
          }
        })
      );

      setParticipantPhotos(photos);
    } catch (error) {
      console.error('Error loading participant photos:', error);
    } finally {
      setIsLoading(false);
    }
  }, [clients, currentUser?.numero, participantPhotos]);

  const checkUnreadMessages = useCallback(async () => {
    if (!currentUser?.pseudo) return;
    
    const unreadMap = {};
    const currentUserUpper = currentUser.pseudo.toUpperCase();
    
    try {
      // Get all chats
      const chatsRef = collection(db, 'chats');
      const chatsSnapshot = await getDocs(chatsRef);
      
      // Process each chat
      for (const chatDoc of chatsSnapshot.docs) {
        const chatId = chatDoc.id;
        if (!chatId.includes(currentUserUpper)) continue;
        
        const otherParticipantPseudo = chatId.split('_')
          .find(p => p !== currentUserUpper)
          ?.toLowerCase();
          
        if (!otherParticipantPseudo) continue;
        
        // Get messages
        const messagesRef = collection(db, 'chats', chatId, 'messages');
        const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));
        const messagesSnapshot = await getDocs(messagesQuery);
        
        const messages = messagesSnapshot.docs.map(doc => doc.data());
        
        // Check for unread messages from this participant
        const hasUnread = messages.some(msg => 
          msg.sender.toUpperCase() === otherParticipantPseudo.toUpperCase() && 
          (!msg.see || msg.see !== 1)
        );
        
        if (hasUnread) {
          unreadMap[otherParticipantPseudo] = true;
        }
      }
      
      setUnreadMessages(unreadMap);
    } catch (error) {
      console.error('Error checking unread messages:', error);
    }
  }, [currentUser?.pseudo]);

  useEffect(() => {
    checkUnreadMessages();
  }, [checkUnreadMessages]);

  useEffect(() => {
    loadParticipantPhotos();
  }, [loadParticipantPhotos]);

  useEffect(() => {
    const unsubscribe = startUsersListener();
    return () => unsubscribe?.();
  }, [startUsersListener]);

  const handleStartChat = useCallback((participant) => {
    if (!participant?.pseudo) {
      console.error('Cannot start chat: participant is missing required data', participant);
      return;
    }
    
    // Close the popup
    setSelectedParticipant(null);
    
    // Initialize chat with the selected participant
    setSelectedClient({
      ...participant,
      pseudo: participant.pseudo.toUpperCase() // Ensure pseudo is uppercase for consistency
    });
    setCurrentStep('chat');
  }, [setSelectedClient, setCurrentStep]);

  const handleParticipantClick = useCallback((participant) => {
    if (!participant?.pseudo) {
      console.error('Invalid participant data:', participant);
      return;
    }
    setSelectedParticipant(participant);
  }, []);

  const getAgeRangeForParticipant = useCallback((age) => {
    const ageNum = parseInt(age);
    if (ageNum <= 24) return '18-25';
    if (ageNum <= 34) return '25-35';
    if (ageNum <= 44) return '35-45';
    if (ageNum <= 54) return '45-55';
    if (ageNum => 54) return '+55';
    return '+55';
  }, []);

  const filteredParticipants = useMemo(() => {
    console.log('All clients:', clients);
    const filtered = clients.filter(participant => {
      console.log('Checking participant:', participant.numero, participant);
      const matchesGender = selectedGenders.size === 0 || selectedGenders.has(participant.genre);
      const matchesObjective = selectedObjectives.size === 0 || selectedObjectives.has(participant.objectif);
      const matchesAge = selectedAgeRanges.size === 0 || selectedAgeRanges.has(getAgeRangeForParticipant(participant.age));

      const shouldInclude = matchesGender && matchesObjective && matchesAge && participant.pseudo !== currentUser?.pseudo;
      console.log('Include participant?', participant.numero, shouldInclude);
      return shouldInclude;
    });
    console.log('Filtered participants:', filtered);
    return filtered;
  }, [clients, selectedGenders, selectedObjectives, selectedAgeRanges, currentUser?.pseudo, getAgeRangeForParticipant]);

  const toggleFilter = useCallback((setter, value) => {
    setter(prev => {
      const newSet = new Set(prev);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  }, []);

  const columns = useMemo(() => {
    console.log('Creating columns from participants:', filteredParticipants);
    const result = {
      left: [],
      center: [],
      right: []
    };

    // Trier les participants par numéro pour assurer un ordre cohérent
    const sortedParticipants = [...filteredParticipants].sort((a, b) => {
      const numA = parseInt(a.numero);
      const numB = parseInt(b.numero);
      return numA - numB;
    });

    sortedParticipants.forEach((participant, index) => {
      console.log('Distributing participant:', participant.numero, 'to column:', index % 3);
      const columnIndex = index % 3;
      if (columnIndex === 0) result.left.push(participant);
      else if (columnIndex === 1) result.center.push(participant);
      else result.right.push(participant);
    });

    console.log('Final columns:', result);
    return result;
  }, [filteredParticipants]);

  const renderColumn = useCallback((participants, columnIndex) => (
    participants.map((participant, index) => (
      <ParticipantCard
        key={participant.numero}
        participant={participant}
        photo={participantPhotos[participant.numero]}
        onClick={() => handleParticipantClick(participant)}
        index={columnIndex * participants.length + index}
        hasUnreadMessages={participant.pseudo ? unreadMessages[participant.pseudo.toLowerCase()] : false}
      />
    ))
  ), [participantPhotos, handleParticipantClick, unreadMessages]);

  if (isLoading) {
    return (
      <div className="participants-screen">
        <div className="loading-spinner">
          <i className="fa fa-spinner fa-spin"></i> Chargement...
        </div>
      </div>
    );
  }

  return (
    <div className="participants-screen">
      <div className="participants-columns">
        <div className="participants-column">
          {columns.left.map((participant, index) => (
            <ParticipantCard
              key={participant.numero}
              participant={participant}
              photo={participantPhotos[participant.numero]}
              onClick={() => handleParticipantClick(participant)}
              index={index}
              hasUnreadMessages={unreadMessages[participant.pseudo]}
            />
          ))}
        </div>
        <div className="participants-column center">
          {columns.center.map((participant, index) => (
            <ParticipantCard
              key={participant.numero}
              participant={participant}
              photo={participantPhotos[participant.numero]}
              onClick={() => handleParticipantClick(participant)}
              index={index}
              hasUnreadMessages={unreadMessages[participant.pseudo]}
            />
          ))}
        </div>
        <div className="participants-column">
          {columns.right.map((participant, index) => (
            <ParticipantCard
              key={participant.numero}
              participant={participant}
              photo={participantPhotos[participant.numero]}
              onClick={() => handleParticipantClick(participant)}
              index={index}
              hasUnreadMessages={unreadMessages[participant.pseudo]}
            />
          ))}
        </div>
      </div>

      {/* Menu des filtres */}
      <div className={`filters-menu ${!isFiltersMenuOpen ? 'hidden' : ''}`}>
        <div className="filter-section">
          <h3>Genre</h3>
          <div className="filter-options">
            <div
              className={`gender-filter ${selectedGenders.has('H') ? 'active' : ''}`}
              onClick={() => toggleFilter(setSelectedGenders, 'H')}
            >
              👨
            </div>
            <div
              className={`gender-filter ${selectedGenders.has('F') ? 'active' : ''}`}
              onClick={() => toggleFilter(setSelectedGenders, 'F')}
            >
              👩
            </div>
          </div>
        </div>

        <div className="filter-section">
          <h3>est ici pour </h3>
          <div className="filter-options">
            {OBJECTIFS.map((objectif) => (
              <div
                key={objectif.id}
                className={`filter-option ${selectedObjectives.has(objectif.id) ? 'active' : ''}`}
                onClick={() => toggleFilter(setSelectedObjectives, objectif.id)}
              >
                <span className="objectif-emoji">{getObjectifEmoji(objectif)}</span>
                <span className="objectif-text">{objectif.title}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="filter-section">
          <h3>Âge</h3>
          <div className="filter-options">
            {['18-25', '25-35', '35-45', '45-55',  '+55'].map((range) => (
              <div
                key={range}
                className={`filter-option ${selectedAgeRanges.has(range) ? 'active' : ''}`}
                onClick={() => toggleFilter(setSelectedAgeRanges, range)}
              >
                {range}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Bouton pour ouvrir/fermer les filtres */}
      <div 
        className="floating-filter-button"
        onClick={() => setIsFiltersMenuOpen(!isFiltersMenuOpen)}
      >
        {isFiltersMenuOpen ? '✕' : '⚡'}
      </div>

      {selectedParticipant && (
        <ParticipantPopup
          participant={selectedParticipant}
          onClose={() => setSelectedParticipant(null)}
          handleChat={handleStartChat}
        />
      )}
    </div>
  );
};

export default ParticipantsScreen;
